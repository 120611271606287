import { Tooltip } from 'antd'
import { useState } from 'react'
import ObjectTooltip from '../Tooltip/ObjectTooltip'

interface ObjectTypeProps {
  type?: string
  value?: string
}

const ObjectRender = (props: ObjectTypeProps) => {
  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false)
  return (
    <Tooltip
      title={<ObjectTooltip type={props.type} nom={props.value} />}
      color={'white'}
      overlayInnerStyle={{ borderRadius: 0 }}
      open={isShowTooltip && !!props.value}
      mouseEnterDelay={0.3}
    >
      <div
        style={{ width: 'max-content' }}
        onMouseLeave={() => {
          setIsShowTooltip(false)
        }}
        onMouseEnter={() => {
          setIsShowTooltip(true)
        }}
      >
        {props.value || '-'}
      </div>
    </Tooltip>
  )
}

export default ObjectRender
