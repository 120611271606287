import { renderNomCode } from '../../../utils'

interface ObjectTooltipProps {
  id?: string
  nom?: string
  type?: string
}

/**
 * The ObjectTooltip will show tooltip when hovering button
 * @author [anhht, anhnq]
 * @param {id, nom, type} string
 * @version 0.1
 */
const ObjectTooltip = (props: ObjectTooltipProps) => {
  const { nom } = props
  var id = renderNomCode(props.nom as string)[0]
  var name = renderNomCode(props.nom as string)[1]

  return (
    <div className="m1-tooltip">
      <div>{props.id}</div>

      {(props.type === 'fournisseur' ||
        props.type === 'destinataire' ||
        props.type === 'transporteur') && (
        <>
          <div>Id : {id} </div>
          <div>Nom : {name}</div>
        </>
      )}

      {(props.type === 'chargement' ||
        props.type === 'modification' ||
        props.type === 'validation' ||
        props.type === 'creation') && (
        <>
          <div>par : utilisateur2487</div>
          <div>le: {nom}</div>
        </>
      )}
      {props.type === 'mise' && (
        <>
          <div>Nom : Utilisateur2156</div>
        </>
      )}
      {props.type === 'tracking' && (
        <>
          <div>N° de suivi :</div>
          <div>{nom}</div>
        </>
      )}
    </div>
  )
}

export default ObjectTooltip
