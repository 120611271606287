import { notification } from 'antd'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { RootState } from '../../../../app/store'
import CustomDropdown, {
  DropdownType,
} from '../../../../components/Common/CustomDropdown/CustomDropdown'
import { Page } from '../../../../enum/pageEnum'
import './ActionRow.scss'
import { selectValidAccount } from '../../../../redux/reducers/accountSlice'
import clsx from 'clsx'
import { getCurrentFiltersText } from '../../../../utils'

interface ActionRowProps {
  isDisplayActionDropdown?: boolean
  isDisableActionButton?: boolean
}

const ActionRow = (props: ActionRowProps) => {
  const { isDisplayActionDropdown, isDisableActionButton } = props
  const [searchParams] = useSearchParams()
  const pageIndex = Number(searchParams.get('page-index'))
  const pageSize = Number(searchParams.get('page-size'))
  const navigate = useNavigate()
  const { currentPage } = useSelector((state: RootState) => state.page)
  const isValidAccount = useSelector(selectValidAccount)

  const onclickOpenModal = () => {
    if (currentPage === Page.ATTENDUS) {
      navigate(
        `/gestion-de-stock/attendu/attendu-create?page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText()}`
      )
    } else if (currentPage === Page.COMMANDES) {
      navigate(
        `/gestion-de-stock/commande/commande-create?page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText()}`
      )
    } else if (currentPage === Page.STOCK) {
      navigate(
        `/gestion-de-stock/stock/stock-create?page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText()}`
      )
    } else {
    }
  }

  const openNotification = () => {
    notification.info({
      message: 'Implémentation ultérieure',
      placement: 'topRight',
      duration: 5,
    })
  }

  return (
    <div className="my-4 mx-10 flex justify-between action-row">
      <CustomDropdown
        className={`${
          isDisplayActionDropdown ? 'block' : 'hidden'
        } action-dropdown`}
        placeholder="Actions"
        width="w-36"
        type={DropdownType.PRIMARY}
        options={[
          {
            value: 'Editer',
            label: (
              <div className="flex items-center border-gray1 border-b border-solid border-0 pb-3">
                <i className="dropdown-icon icon-editor "></i>
                Editer
              </div>
            ),
          },
          {
            value: 'Historique',
            label: (
              <div className="flex items-center border-gray1 border-b border-solid border-0 pb-3">
                <i className="dropdown-icon icon-history"></i>
                Historique
              </div>
            ),
          },
          {
            value: 'Imprimer',
            label: (
              <div className="flex items-center border-gray1 border-b border-solid border-0 pb-3">
                <i className=" dropdown-icon icon-printer"></i>
                Imprimer
              </div>
            ),
          },
          {
            value: 'Supprimer',
            label: (
              <div className="flex items-center text-red pb-2">
                <i className="dropdown-icon icon-delete"></i>
                Supprimer
              </div>
            ),
          },
        ]}
      />
      <div className="flex ml-5">
        <div
          className="rounded-md mr-1 h-10 w-11 flex items-center justify-center"
          onClick={openNotification}
        >
          <i className="icon-export w-full h-full"></i>
        </div>
        <div
          className="rounded-md mr-1 h-10 w-11 flex items-center justify-center"
          onClick={openNotification}
        >
          <i className="icon-import w-full h-full"></i>
        </div>
        <div
          className={clsx(
            'rounded-md h-10 w-11 flex items-center justify-center',
            isValidAccount ? 'cursor-pointer' : 'rounded-md'
          )}
          onClick={() => isValidAccount && onclickOpenModal()}
        >
          <i
            className={clsx(
              'w-full h-full',
              isDisableActionButton ? 'icon-add' : 'icon-add-active'
            )}
          ></i>
        </div>
      </div>
    </div>
  )
}

export default ActionRow
