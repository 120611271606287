import { t } from 'i18next'
import _, { cloneDeep } from 'lodash'
import moment from 'moment'
import Define from '../../constants/define'
import { EtaAttendu } from '../../enum/EtaAttendu'
import { Attendu, AttenduDataType, ReferenceAttendu } from '../../models'
import { randomInt } from '../random'

export const makeTableValue = (attenduArray: Attendu[]) => {
  let myAttenduList: AttenduDataType[] = []
  if (attenduArray) {
    attenduArray.forEach((e, index) => {
      myAttenduList.push(makeRowParentValue(e, index))
    })
  }
  return myAttenduList
}

function sortByReference(list: ReferenceAttendu[]) {
  return list.sort(function (a, b) {
    const nameA = a.reference_number?.toUpperCase()
    const nameB = b.reference_number?.toUpperCase()
    if (!nameA || !nameB) {
      return 0
    } else if (nameA < nameB) {
      return -1
    } else if (nameA > nameB) {
      return 1
    } else {
      // names must be equal
      return 0
    }
  })
}

const makeRowParentValue = (attendu: Attendu, index: number) => {
  let createAt = (attendu?.create_at || 0) * 1000
  let updateAt = (attendu?.update_at || 0) * 1000
  // if attendu has only one ref then it will show information of ref in main line
  const onlyChildren =
    attendu?.att_production?.length === 1
      ? attendu?.att_production[0]
      : undefined

  let qtePalette = 0
  //calc main line qte (palette)
  if (attendu.att_production) {
    attendu.att_production.forEach((item) => {
      if (item.conditionnement?.niveau !== Define.MAX_CDN_LEVEL) return
      if (!item.quantite) return
      qtePalette += item.quantite
    })
  }

  let sousQte = 0
  //calc main line sous qte (carton)
  if (attendu.att_production) {
    attendu.att_production.forEach((item) => {
      if (
        item.conditionnement?.niveau === Define.MAX_CDN_LEVEL - 1 &&
        item.quantite
      )
        sousQte += item.quantite
      if (
        item.conditionnement?.niveau === Define.MAX_CDN_LEVEL &&
        item.quantite &&
        item.sous_qte
      )
        sousQte += item.quantite * item.sous_qte
    })
  }

  let isAllLinesHaveSameSSCC = attendu.att_production?.every(
    (item) =>
      item.reference_number === attendu.att_production[0].reference_number
  )
  let countProduit = _.uniq(
    attendu.att_production?.map((item) => item.reference_number)
  ).length

  let filterLinesHaveConditionnement = attendu.att_production?.filter(
    (item) => !!item.conditionnement
  )

  let isAllLinesHaveSameNiveau = filterLinesHaveConditionnement?.every(
    (item) =>
      item.conditionnement?.niveau ===
      attendu.att_production[0].conditionnement?.niveau
  )

  let countCdn = _.uniq(
    filterLinesHaveConditionnement?.map((item) => item.conditionnement?.niveau)
  ).length

  let isAllBlocageEntree = attendu?.att_production?.every(
    (el) => !!el.reference_product?.blocage_entree
  )
  let isAllBlocageSortie = attendu?.att_production?.every(
    (el) => !!el.reference_product?.blocage_sortie
  )

  let atLeastOneLineBloc = attendu?.att_production?.find(
    (el) =>
      !!el.reference_product?.blocage_entree ||
      !!el.reference_product?.blocage_sortie
  )
  let atLeastOneLineNotBloc = attendu?.att_production?.find(
    (el) =>
      !el.reference_product?.blocage_entree &&
      !el.reference_product?.blocage_sortie
  )

  let value: AttenduDataType = {
    id: attendu.id,
    key: attendu.id || '',
    detail: '',
    select: true,
    mouvement: attendu.mouvement,
    produit: isAllLinesHaveSameSSCC
      ? attendu.att_production[0]?.reference_number || ''
      : '',
    conditionement: isAllLinesHaveSameNiveau
      ? attendu.att_production[0]?.conditionement || ''
      : '',
    niveau: isAllLinesHaveSameNiveau
      ? attendu.att_production[0]?.conditionnement?.niveau || 0
      : 0,
    qte: qtePalette || 0,
    sousqte: sousQte || 0,
    palette: '',
    dluo: '-',
    bl: attendu.bl || '',
    commande: attendu.no_commande || '',
    fournisseur: attendu?.att_fournisseur?.code_nom
      ? attendu?.att_fournisseur?.code_nom
      : '',
    commandeAttendu: attendu.id,
    chrono: attendu.chrono || '',
    lot: '',
    creation: moment(createAt).format(t('time-format')),
    modification: moment(updateAt).format(t('time-format')),
    validation: attendu?.validation_time
      ? moment((attendu?.validation_time || 0) * 1000).format(t('time-format'))
      : '',
    created_by: attendu.created_by || '',
    modification_by: attendu.modification_by || '',
    validateBy: attendu?.validation_by || '',
    ligne: '-',
    att_fournisseur: attendu.att_fournisseur || {},
    eta: attendu.eta || EtaAttendu.CREATED,
    reference_id: onlyChildren?.reference_id,
    countProduit: isAllLinesHaveSameSSCC ? undefined : countProduit,
    countCdn: isAllLinesHaveSameNiveau ? undefined : countCdn,
    isDisplayMainLineStatut: !!atLeastOneLineBloc && !!atLeastOneLineNotBloc,
    blocage_entree: isAllBlocageEntree,
    blocage_sortie: isAllBlocageSortie,
    children: [],
    status: attendu.status,
  }
  // only show children if there are more than one ref
  if (attendu.att_production) {
    sortByReference(cloneDeep(attendu.att_production)).forEach((e) => {
      value.children?.push(makeRowChildValue(attendu, e))
    })
  }

  // calc total pieses
  if (attendu.att_production) {
    let total = 0
    attendu.att_production.forEach((item) => {
      const isQte = item.sous_qte && item.sous_qte > 0
      if (isQte) total += item.total_pieses || 0
      else total += Number(item.quantite) * Number(item.total_pieses)
    })

    value.total_pieses = total
  }

  return value
}

const makeRowChildValue = (attendu: Attendu, production: ReferenceAttendu) => {
  let value: AttenduDataType = {
    key: production.id || randomInt(20),
    detail: '',
    select: true,
    mouvement: attendu.mouvement,
    produit: production?.reference_number || '',
    conditionement: production?.conditionement || '',
    niveau: attendu?.conditionnement?.niveau,
    qte: production.quantite || 0,
    sousqte: production.sous_qte || 0,
    palette: production.sscc_ob?.num || '',
    dluo: production.dluo
      ? moment(Number(production.dluo) * 1000).format(t('date-format'))
      : '-',
    bl: attendu.bl || '',
    commande: attendu.no_commande || '',
    fournisseur: attendu?.att_fournisseur?.code_nom
      ? attendu?.att_fournisseur?.code_nom
      : '',
    chrono: production.chrono || '',
    lot: production.lot || '',
    creation:
      moment(Number(attendu.create_at) * 1000).format(t('time-format')) || '',
    modification:
      moment(Number(attendu.update_at) * 1000).format(t('time-format')) || '',
    validation: production.validation_time
      ? moment((production?.validation_time || 0) * 1000).format(
          t('time-format')
        )
      : '',
    created_by: attendu.created_by,
    modification_by: attendu.modification_by,
    validateBy: production.validation_by,
    ligne: '-',
    att_fournisseur: attendu.att_fournisseur || {},
    volume_qte: production?.volume_qte,
    poids_qte: production?.poids_qte,
    volume_sousqte: production?.volume_sousqte,
    poids_sousqte: production?.poids_sousqte,
    commandeAttendu: '',
    reference_id: production.reference_id,
    eta: production.eta,
    total_pieses: production.total_pieses,
    blocage_entree: production.reference_product?.blocage_entree,
    blocage_sortie: production.reference_product?.blocage_sortie,
    pre_statut: production.reference_product?.pre_block_status,
    status: production.status,
  }

  const isQte = production.sous_qte && Number(production.sous_qte) > 0
  if (isQte) value.total_pieses = production.total_pieses
  else {
    const qte = Number(production.quantite)
    const totalPiece = Number(production.total_pieses)
    value.total_pieses = qte * totalPiece
  }

  return value
}
