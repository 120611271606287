import { Tooltip } from 'antd'
import { useState } from 'react'
import { renderDigitsByNumber } from '../../../utils'
import BLTooltip from '../Tooltip/BLTooltip'

interface BLRenderProps {
  data?: string
}

const BL_NUMBER_DIGITS = 10

const BLRender = (props: BLRenderProps) => {
  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false)
  const dataLength = props.data?.length || 0

  return (
    <div className="text-secondary font-semibold hover-text">
      <Tooltip
        title={<BLTooltip num={props.data} />}
        color="#FFFFFF"
        overlayInnerStyle={{ borderRadius: 0 }}
        open={isShowTooltip}
        mouseEnterDelay={0.3}
      >
        <div
          style={{ width: 'max-content' }}
          onMouseLeave={() => {
            setIsShowTooltip(false)
          }}
          onMouseEnter={() => {
            if (dataLength > BL_NUMBER_DIGITS) setIsShowTooltip(true)
          }}
        >
          {renderDigitsByNumber(props.data, BL_NUMBER_DIGITS)}
        </div>
      </Tooltip>
    </div>
  )
}

export default BLRender
