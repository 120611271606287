import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Checkbox, Empty, Pagination, Tooltip, message } from 'antd'
import Table, { ColumnsType } from 'antd/es/table'
import { t } from 'i18next'
import React, { Key, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Outlet, useNavigate, useSearchParams } from 'react-router-dom'
import { RootState } from '../../../app/store'
import BLRender from '../../../components/Common/DataType/BLType'
import ATTENDU_ICON from '../../../assets/icons/Attendu icone.svg'
import ConditionnementRender from '../../../components/Common/DataType/ConditionnementType'
import DatetimeUserRender from '../../../components/Common/DataType/DatetimeUserRender'
import EtatRender from '../../../components/Common/DataType/EtatType'
import MovementRender from '../../../components/Common/DataType/MovementType'
import ObjectRender from '../../../components/Common/DataType/ObjectType'
import QteRender from '../../../components/Common/DataType/QteType'
import SelectRender from '../../../components/Common/DataType/SelectType'
import StatutRender from '../../../components/Common/DataType/StatutType'
import SortColumn, {
  getDefaultSorting,
} from '../../../components/Common/SortColumn'
import MovementHeaderTooltip from '../../../components/Common/Tooltip/MovementHeaderTooltip'
import StatutTitleTooltip from '../../../components/Common/Tooltip/StatutHeaderTooltip'
import { Page, PagingEnum } from '../../../enum'
import { StatusAttendu } from '../../../enum/StatusAttendu'
import { useRenderDataByInterval } from '../../../hook/useRenderDataByInterval'
import useScroll from '../../../hook/useScroll'
import { AttenduDataType } from '../../../models'
import {
  getAllAttendu,
  getTotalAttendu,
} from '../../../redux/reducers/attenduSlice'
import {
  convertRemToPixels,
  getCurrentFiltersText,
  renderDigitsByNumber,
} from '../../../utils'
import { makeTableValue } from '../../../utils/attendu/makeTableValue'
import ActionRow from '../components/ActionRow/ActionRow'
import ResearchBar from '../components/ResearchBar/ResearchBar'
import {
  ModalName,
  updateAttenduStatus,
  updateDataAttendu,
} from '../modal/customModalSlice'
import './Attendu.scss'
import Define from '../../../constants/define'
import { EtaAttendu } from '../../../enum/EtaAttendu'

const Attendu = () => {
  const navigate = useNavigate()
  const { choosingClient, choosingCompany, choosingWarehouse } = useSelector(
    (state: RootState) => state.account
  )
  const [searchParams] = useSearchParams()
  const pageIndex = searchParams.get('page-index') || 1
  const pageSize = searchParams.get('page-size') || 25
  // const count = searchParams.get('count')
  const [messageApi, contextHolder] = message.useMessage()
  const [expandedRowKeys, setExpandedRowKeys] = useState<Key[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [rowHover, setRowHover] = useState<React.Key>()
  const [isShowLess, setIsShowLess] = useState<boolean>(false)
  const [isCollapsible, setIsCollapsible] = useState<boolean>(false)

  const { currentListAttendu, totalAttendu } = useSelector(
    (state: RootState) => state.attendu
  )

  const dispatch = useDispatch()

  const searchAttendu = useCallback(
    (values: any) => {
      const searchParams = new URLSearchParams(values)
      const queryString = searchParams.toString()
      navigate(
        `/gestion-de-stock/attendu?page-index=1&page-size=${pageSize}&${queryString}`
      )
      if (Number(pageIndex) === 1) {
        dispatch(getAllAttendu({ isRealTime: false }))
      }
      dispatch(getTotalAttendu())
    },
    [dispatch, navigate, pageIndex, pageSize]
  )

  const mapFieldByTableCol = {
    etat: 'eta',
    bl: 'bl',
    creation: 'created_at',
    modification: 'updated_at',
    validation: 'validation_time',
    fournisseur: 'supplier',
    produit: 'reference_number',
    chrono: 'chrono',
  }

  const handleTableChange = async (p: any, f: any, sorter: any) => {
    // @ts-ignore
    const fieldSort = mapFieldByTableCol[sorter.field]
    if (fieldSort) {
      navigate(
        `/gestion-de-stock/attendu?page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText(
          ['sort', 'direction']
        )}&sort=${fieldSort}&direction=${sorter.order}`
      )
      dispatch(getAllAttendu({ isRealTime: false }))
    }
  }

  useRenderDataByInterval({
    page: Page.ATTENDUS,
    intervalTime: Define.ONE_MINUTE,
    dispatch,
    pageIndex,
    pageSize,
    navigate,
  })

  const columns: ColumnsType<AttenduDataType> = [
    {
      title: <div className="text-[#808080]">Détail</div>,
      dataIndex: 'detail',
      key: 'detail',
      width: 80,
      fixed: 'left',
    },
    {
      title: () => (
        <Checkbox
          onChange={(e) => {
            if (e.target.checked) {
              let fullArray: Array<Key> = []
              data.forEach((item) => {
                fullArray.push(item.key)
                item.children?.map((i) => fullArray.push(i.key))
              })

              setSelectedRowKeys(fullArray)
            } else {
              setSelectedRowKeys([])
            }
          }}
          className="checkbox-gray flex justify-center"
        />
      ),
      dataIndex: 'select',
      key: 'select',
      width: 80,
      render: (_, row) => {
        return (
          <SelectRender
            isMainLine={!!row.children}
            isCheck={selectedRowKeys.includes(row.key)}
            onChangeChecked={(e) => {
              if (e.target.checked) {
                setSelectedRowKeys([...selectedRowKeys, row.key])
              } else {
                setSelectedRowKeys(
                  [...selectedRowKeys].filter((i) => i !== row.key)
                )
              }
            }}
            screenColor="gray"
          />
        )
      },
      fixed: 'left',
    },
    {
      title: () => (
        <Tooltip
          title={<MovementHeaderTooltip />}
          placement="bottom"
          color={'white'}
          overlayClassName="movement-header-tooltip"
          mouseEnterDelay={0.5}
          mouseLeaveDelay={0.2}
          overlayInnerStyle={{
            position: 'absolute',
            top: 0,
            borderRadius: 0,
            height: 'auto',
            left: -105,
          }}
        >
          Mouvement
        </Tooltip>
      ),
      dataIndex: 'mouvement',
      key: 'mouvement',
      width: 280,
      className: 'first-column',
      render: (_, row) => {
        return (
          <MovementRender
            movement={'EX'}
            // movement={row.mouvement}
            type={'10'}
            isChildren={!!row.children}
          />
        )
      },
    },
    {
      title: 'Etat',
      dataIndex: 'etat',
      key: 'etat',
      width: 90,
      defaultSortOrder: getDefaultSorting('eta'),
      sortIcon: ({ sortOrder }) => <SortColumn sortOrder={sortOrder} />,
      sorter: true,
      render: (_, row) => {
        return (
          <EtatRender
            type={row.eta || EtaAttendu.CREATED}
            isMainLine={!!row.children}
            isHover={row.key === rowHover}
            screen={ModalName.ATTENDU}
          />
        )
      },
    },
    {
      title: 'Produit',
      dataIndex: 'produit',
      key: 'produit',
      width: 200,
      defaultSortOrder: getDefaultSorting('reference_number'),
      sortIcon: ({ sortOrder }) => <SortColumn sortOrder={sortOrder} />,
      sorter: true,
      render: (_, row) => {
        return (
          <>
            {row.countProduit && row.countProduit > 1 ? (
              <div
                className="rounded-full bg-[#0189E3] text-center mouvement-hover truncate"
                style={{ height: 23, width: 23 }}
              >
                <div
                  className="flex justify-center items-center"
                  style={{ height: 23 }}
                >
                  <div className="text-white mouvement-hover-text">
                    {row.countProduit}
                  </div>
                </div>
              </div>
            ) : row.produit ? (
              <div
                className={`text-secondary truncate ${
                  row.children ? 'font-semibold' : 'font-medium'
                } `}
              >
                <Link
                  to={`reference/only-view/${
                    row.reference_id
                  }?page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText()}`}
                  className={`hover-text ${
                    !row.reference_id && `disabled-link`
                  } `}
                >
                  {row.produit}
                </Link>{' '}
              </div>
            ) : (
              '-'
            )}
          </>
        )
      },
    },
    {
      title: 'Conditionnement',
      dataIndex: 'conditionnement',
      key: 'conditionnement',
      width: 170,
      render: (_, row) => {
        return (
          <ConditionnementRender
            type={row.conditionement}
            countCdn={row.countCdn}
            isMainLine={!!row.children}
            niveau={row.niveau}
            isHover={row.key === rowHover}
          />
        )
      },
    },
    {
      title: 'Qte',
      dataIndex: 'qte',
      key: 'qte',
      width: 100,
      sortIcon: ({ sortOrder }) => <SortColumn sortOrder={sortOrder} />,
      sorter: (a, b) => a.qte - b.qte,
      render: (_, row) => {
        return (
          <QteRender
            data={row.qte}
            volume={row.volume_qte}
            poids={row.poids_qte}
            notShowTooltip={!!row.children}
          />
        )
      },
    },
    {
      title: 'Sous-Qte',
      dataIndex: 'sousqte',
      key: 'sousqte',
      width: 140,
      sortIcon: ({ sortOrder }) => <SortColumn sortOrder={sortOrder} />,
      sorter: (a, b) => a.sousqte - b.sousqte,
      render: (_, row) => {
        return (
          <QteRender
            data={row.niveau === 1 && row.sousqte > 0 ? 0 : row.sousqte}
            volume={row.volume_sousqte}
            poids={row.poids_sousqte}
            notShowTooltip={!!row.children}
          />
        )
      },
    },

    {
      title: 'Total pièce',
      dataIndex: 'total_pieses',
      key: 'total_pieses',
      width: 200,
      sortIcon: ({ sortOrder }) => <SortColumn sortOrder={sortOrder} />,
      sorter: (a, b) => (a.total_pieses || 0) - (b.total_pieses || 0),
    },
    {
      title: 'Palette',
      dataIndex: 'palette',
      key: 'palette',
      width: 210,
      render: (_, row) => {
        return (
          <>
            {row.palette ? (
              <div className="text-secondary font-semibold hover-text">
                {row.palette || '-'}
              </div>
            ) : (
              <>-</>
            )}
          </>
        )
      },
    },
    {
      title: 'DLUO',
      dataIndex: 'dluo',
      key: 'dluo',
      width: 150,
    },
    {
      title: () => <StatutTitleTooltip />,
      dataIndex: 'statut',
      key: 'statut',
      width: 80,
      render: (_, row) => {
        return (
          <>
            {row.isDisplayMainLineStatut || row.children?.length === 0 ? (
              '-'
            ) : (
              <StatutRender
                blocage_entree={row.blocage_entree}
                blocage_sortie={row.blocage_sortie}
                previousStatut={row.pre_statut}
                isHover={row.key === rowHover}
                isMainLine={!!row.children}
              />
            )}
          </>
        )
      },
    },
    {
      title: 'BL',
      dataIndex: 'bl',
      key: 'bl',
      width: 110,
      defaultSortOrder: getDefaultSorting('bl'),
      sortIcon: ({ sortOrder }) => <SortColumn sortOrder={sortOrder} />,
      sorter: true,
      render: (_, row) => {
        return (
          <div
            className="cursor-pointer"
            onClick={() => {
              if (row.bl && row.commandeAttendu) {
                dispatch(
                  updateAttenduStatus(row.status || StatusAttendu.CREATED)
                )

                if (
                  row.status === StatusAttendu.UNASSIGNED ||
                  row.status !== StatusAttendu.CREATED
                ) {
                  navigate(
                    `/gestion-de-stock/attendu/attendu-on-mission/${
                      row.commandeAttendu
                    }?page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText()}`,
                    { replace: true }
                  )
                }
                if (row.status === StatusAttendu.CREATED) {
                  navigate(
                    `/gestion-de-stock/attendu/attendu-edit/${
                      row.commandeAttendu
                    }?page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText()}`,
                    {
                      replace: true,
                    }
                  )
                }
              }
            }}
          >
            <BLRender data={row.bl} />
          </div>
        )
      },
    },
    {
      title: 'Commande',
      dataIndex: 'commande',
      key: 'commande',
      width: 120,
      render: (_, row) => {
        return <>{row.commande ? <BLRender data={row.commande} /> : <>-</>}</>
      },
    },
    {
      title: 'Fournisseur',
      dataIndex: 'fournisseur',
      key: 'fournisseur',
      width: 280,
      defaultSortOrder: getDefaultSorting('supplier'),
      sortIcon: ({ sortOrder }) => <SortColumn sortOrder={sortOrder} />,
      sorter: true,
      render: (_, row) => {
        if (row.fournisseur) {
          return (
            <div
              className="text-secondary font-semibold hover-text"
              onClick={() => {
                dispatch(updateDataAttendu(row.att_fournisseur))
                navigate(
                  `/gestion-de-stock/attendu/fournisseur/only-view/${
                    row.att_fournisseur.id
                  }?page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText()}`
                )
              }}
            >
              <ObjectRender value={row.fournisseur} type={'fournisseur'} />
            </div>
          )
        } else {
          return <div>-</div>
        }
      },
    },
    {
      title: 'Chrono',
      dataIndex: 'chrono',
      key: 'chrono',
      width: 145,
      defaultSortOrder: getDefaultSorting('chrono'),
      sortIcon: ({ sortOrder }) => <SortColumn sortOrder={sortOrder} />,
      sorter: true,
      render: (_, row) => {
        const isParent = Boolean(row.children)
        if (isParent) {
          if (row.chrono)
            return (
              <Link
                className="hover-text text-[#808080]"
                to={`/gestion-de-stock/attendu/chrono/${
                  row.id
                }?page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText()}`}
              >
                {row.chrono}
              </Link>
            )
          return (
            <span className="text-[#808080] font-semibold hover-text">-</span>
          )
        } else {
          if (row.chrono)
            return (
              <Link
                className="hover-text text-[#808080]"
                to={`/gestion-de-stock/attendu/chrono/sscc/${row.palette}`}
              >
                {row.chrono}
              </Link>
            )
          return <span className="hover-text text-[#808080]">-</span>
        }
      },
    },

    {
      title: 'Lot',
      dataIndex: 'lot',
      key: 'lot',
      width: 210,
      render: (_, row) => {
        return (
          <>
            {row.lot ? (
              <Tooltip title={row.lot}>
                <div className="text-secondary font-semibold hover-text">
                  {renderDigitsByNumber(row.lot, 11, true)}
                </div>
              </Tooltip>
            ) : (
              <>-</>
            )}
          </>
        )
      },
    },

    {
      title: 'Création',
      dataIndex: 'creation',
      key: 'creation',
      width: 170,
      defaultSortOrder: getDefaultSorting('created_at', 'ascend'),
      sortIcon: ({ sortOrder }) => <SortColumn sortOrder={sortOrder} />,
      sorter: true,
      render: (_, row) => {
        return (
          <DatetimeUserRender datetime={row.creation} user={row.created_by} />
        )
      },
    },
    {
      title: 'Modification',
      dataIndex: 'modification',
      key: 'modification',
      width: 170,
      defaultSortOrder: getDefaultSorting('updated_at'),
      sortIcon: ({ sortOrder }) => <SortColumn sortOrder={sortOrder} />,
      sorter: true,
      render: (_, row) => {
        return (
          <DatetimeUserRender
            datetime={row.modification}
            user={row.modification_by}
          />
        )
      },
    },
    {
      title: 'Validation',
      dataIndex: 'validation',
      key: 'validation',
      width: 170,
      defaultSortOrder: getDefaultSorting('validation_time'),
      sortIcon: ({ sortOrder }) => <SortColumn sortOrder={sortOrder} />,
      sorter: true,
      render: (_, row) => {
        return (
          <DatetimeUserRender datetime={row.validation} user={row.validateBy} />
        )
      },
    },
    {
      title: 'Ligne',
      dataIndex: 'ligne',
      key: 'ligne',
      width: 80,
    },
  ]

  const rowClassName = (record: AttenduDataType, index: number) => {
    if (!!record.children) {
      if (index % 2 === 0) return 'table-row-main-even'
      else return ' table-row-main-odd'
    } else return 'table-row-child'
  }

  const isChooseWarehouse =
    choosingClient && choosingWarehouse && choosingCompany

  const data = isChooseWarehouse
    ? makeTableValue(currentListAttendu.attenduList)
    : []

  const height =
    window.innerHeight - 240 - convertRemToPixels(isCollapsible ? 14 : 4)

  useScroll(data)

  return (
    <div>
      {contextHolder}
      <ResearchBar
        screen={ModalName.ATTENDU}
        onSearch={searchAttendu}
        listFilters={[
          'Libelle',
          'Fournisseur',
          'AttenduType',
          'AttenduMouvement',
          'AttenduEtat',
          'Chrono',
          'Debut',
          'Fin',
          'Reference',
          'Status',
          'AttenduPalette',
          'Lot',
        ]}
        requiredToAdd={['client', 'company', 'warehouse']}
        requiredToFilters={['client', 'company', 'warehouse']}
        isCollapsible={isCollapsible}
        setIsCollapsible={setIsCollapsible}
        searchBarHeight={10}
        totalIn={isChooseWarehouse ? currentListAttendu?.metadata?.total : 0}
        total={isChooseWarehouse ? totalAttendu?.number : 0}
      />
      <div className="flex ml-10 justify-between">
        <div className="flex items-center">
          <img src={ATTENDU_ICON} alt="attendu-icon" className="mr-3" />
          <div className="page-title-heading-1">ATTENDUS</div>
        </div>
        <ActionRow
          isDisplayActionDropdown={selectedRowKeys.length >= 1}
          isDisableActionButton={
            !choosingClient && !choosingCompany && !choosingWarehouse
          }
        />
      </div>

      <div className="attendu flex justify-center">
        <Table
          virtual
          onChange={handleTableChange}
          showSorterTooltip={false}
          className="attendu-cmd-stock table-wrapper relative text-[#EBEBEB]"
          columns={columns}
          pagination={false}
          scroll={{
            y: height,
          }}
          indentSize={0}
          loading={currentListAttendu.loading}
          dataSource={data}
          rowClassName={rowClassName}
          locale={{
            emptyText: (
              <span>
                <Empty description={''} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                <p className="text-center">
                  {choosingClient && choosingWarehouse && choosingCompany
                    ? t('noStockFound')
                    : t('notEnoughCondition')}
                </p>
              </span>
            ),
          }}
          expandable={{ expandedRowKeys }}
          expandIcon={({ expanded, onExpand, record }) =>
            // only show dropdown icon if only has children
            record.children !== undefined && record.children?.length > 0 ? (
              expanded ? (
                <UpOutlined
                  onClick={() => {
                    setExpandedRowKeys(
                      expandedRowKeys.filter((item) => item !== record.key)
                    )
                  }}
                />
              ) : (
                <DownOutlined
                  onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                    if (e.shiftKey || e.ctrlKey) {
                      setExpandedRowKeys([...expandedRowKeys, record.key])
                      if (expandedRowKeys.length >= 10) {
                        messageApi.warning(
                          'Attention : l’affichage de plus de Détails peut limiter vos performances'
                        )
                      }
                    } else {
                      setExpandedRowKeys([record.key])
                    }
                  }}
                />
              )
            ) : (
              <></>
            )
          }
          onRow={(record, rowIndex) => {
            return {
              onMouseEnter: (e) => {
                setRowHover(record.key)
              },
              onMouseLeave: (e) => {
                setRowHover(undefined)
              },
            }
          }}
        />
      </div>
      {choosingClient &&
        choosingWarehouse &&
        choosingCompany &&
        currentListAttendu.attenduList &&
        currentListAttendu.attenduList.length !== 0 && (
          <div className="custom-paging-attendu-stock-commande">
            <Pagination
              className="custom-pagination"
              locale={{ items_per_page: '' }}
              total={currentListAttendu.metadata?.total || 0}
              defaultPageSize={PagingEnum.DEFAULT_PAGE_SIZE}
              showLessItems={isShowLess}
              pageSizeOptions={[
                PagingEnum.DEFAULT_PAGE_SIZE,
                PagingEnum.PAGE_SIZE_1,
                PagingEnum.PAGE_SIZE_2,
              ]}
              pageSize={Number(pageSize)}
              current={Number(pageIndex)}
              showSizeChanger
              showTotal={(total, range) =>
                `${range[0] >= 0 ? range[0] : 0}-${
                  range[1] >= 0 ? range[1] : 0
                } sur ${total}`
              }
              onChange={(page, size) => {
                navigate(
                  `/gestion-de-stock/attendu?page-index=${page}&page-size=${size}${getCurrentFiltersText()}`
                )
                setIsShowLess(() => {
                  return page > 4
                })
              }}
            />
          </div>
        )}
      <Outlet />
    </div>
  )
}

export default Attendu
