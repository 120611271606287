interface BLTooltipProps {
  num?: string
}

/**
 * The BLTooltip will show tooltip when hovering button
 * @author [anhht]
 * @param {number} string;
 */
const BLTooltip = (props: BLTooltipProps) => {
  return (
    <div className="m1-tooltip">
      <div>{props.num}</div>
    </div>
  )
}

export default BLTooltip
